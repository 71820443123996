<template>
  <div>
    <div class="header">
      <van-nav-bar
        :title="$t('set.bank')"
        left-arrow
        @click-left="$router.go(-1)"
      />
    </div>
    <div class="maincontent">
      <van-row class="spacing">
        <van-form ref="form">
          <van-row class="field">
            <van-row
              style="border-bottom: 1px solid rgb(208, 208, 209); padding-bottom: 10px;"
              ><span>{{ $t('set.phone') }}</span>
            </van-row>
            <van-field
              v-model="banklist.phone"

              :placeholder="$t('set.phone')"

            >
            </van-field>
          </van-row>

          <van-row class="field">
            <van-row
              style="border-bottom: 1px solid rgb(208, 208, 209); padding-bottom: 10px;"
              ><span>{{ $t('set.bankname') }} </span>
            </van-row>
            <van-field
              v-model="banklist.bankname"
              :placeholder="$t('set.bankname')"

            >
              </van-field>
          </van-row>
          <van-row class="field">
            <van-row
              style="border-bottom: 1px solid rgb(208, 208, 209); padding-bottom: 10px;"
              ><span>{{ $t('set.banknum') }} </span>
            </van-row>
            <van-field
              v-model="banklist.banknum"
              :placeholder="$t('set.banknum')"

            >
            </van-field>
          </van-row>
          <van-row class="field">
            <van-row
              style="border-bottom: 1px solid rgb(208, 208, 209); padding-bottom: 10px;"
              ><span>{{ $t('set.name') }} </span>
            </van-row>
            <van-field
              v-model="banklist.name"
              :placeholder="$t('set.name')"

            >
            </van-field>
          </van-row>
        </van-form>
        <van-button
          class="submitBtn"
           @click="onSubmit"
          >{{ $t('common.submit') }}
        </van-button>

      </van-row>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isshow: true,
      bankshow: false,
      banklist: [],
      bankname: '',
      phone: '',
      banknum: '',
      name: ''
    }
  },
  created() {
    this.getlist()
  },
  methods: {
    // 获取联系方式列表
    async getlist() {
      const { data } = await this.$http.get('/home/home/getbanklist')
      if (data) {
        if (data.code === 200) {
          if (!data.data.banklist) {
            this.banklist.bankname = '',
            this.banklist.phone = '',
            this.banklist.banknum = '',
            this.banklist.name = ''
          } else {
            this.banklist = data.data.banklist
          }
        } else {
          this.$toast.fail(this.$t('common.actionfail'))
        }
      }
    },
    async onSubmit() {
      if (!this.banklist.bankname || !this.banklist.phone || !this.banklist.banknum || !this.banklist.name) {
        console.log(this.banklist)
        return false
      }
      // 组装数据
      const postform = {
        bankname: this.banklist.bankname,
        phone: this.banklist.phone,
        banknum: this.banklist.banknum,
        name: this.banklist.name
      }

      const { data } = await this.$http.post('/home/home/addcontact', postform)
      if (data) {
        if (data.code === 200) {
          this.$toast.success(this.$t('common.success'))
          setTimeout(() => {
            this.$router.push('/index')
          }, 300)
        } else {
          this.$toast.fail(this.getlang(data.msg, localStorage.getItem('lang')))
        }
      }
    }

  }

}
</script>

<style lang="less" scoped>
.maincontent {
  padding-top: 50px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.53333rem;
  background: #f7f7f7;
  height: 100vh;
  .spacing {
    margin-top: 1rem;
    .field {
      background-color: #f1f1f2;
      margin-top: 0.53333rem;
      width: 90%;
      margin-left: 5%;
      border-radius: 0.53333rem;
      padding: .16667rem;
      span {
        color: #000;
        font-size: 0.86667rem;
      }
      .van-cell {
        font-size: 0.98667rem;
        background-color: #f1f1f2 !important;
        color: #fff !important;
        height: 4rem;
      }
      .van-field__control {
        padding-right: 1.2rem;
      }
      /deep/.van-field input {
        color: #000;
        text-align: center;
      }
      /deep/.van-field__error-message {
        text-align: center;
      }
    }
    .submitBtn {
      margin-top: 10%;
      width: 80%;
      letter-spacing: 0.53333rem;
      height: 4rem;
      font-size: 0.53333rem;
      color: #fff;
      background-color: #004ea3;
      border-radius: 0.53333rem;
      border: none;
      box-shadow: 0 0 1.33333rem #004ea3;
    }
  }
}
</style>
